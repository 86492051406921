import React from "react"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { useTranslation } from "react-i18next"

import Layout from "../components/Layout/Layout"
import BlogItem from "../components/BlogItem/BlogItem"
import Link from "../components/Link/Link"

import "../styles/blog.scss"

export default function Blog({ data }) {
  const { posts, categories, featuredPost } = data
  const { featuredBlogPost } = featuredPost?.edges?.[0].node
  const { pathname } = useLocation()
  const { t } = useTranslation()

  return (
    <Layout
      variant="blog"
      title="Blog"
      description="Dayra's blog, home to the newest fintech finds and developments throughout Egypt and the Middle East."
      cmsImage={{
        src:
          featuredBlogPost?.heroImage?.image?.gatsbyImageData?.images?.fallback
            ?.src,
        height: featuredBlogPost?.heroImage?.image?.gatsbyImageData?.height,
        width: featuredBlogPost?.heroImage?.image?.gatsbyImageData?.width,
        alt: featuredBlogPost?.heroImage?.altText,
      }}
    >
      <BlogItem
        category={featuredBlogPost?.category?.title}
        title={featuredBlogPost?.title}
        author={featuredBlogPost?.author?.name}
        date={featuredBlogPost?.publishDate}
        summary={featuredBlogPost?.description?.description}
        image={featuredBlogPost?.heroImage?.image}
        imageAlt={featuredBlogPost?.heroImage?.altText}
        slug={featuredBlogPost?.slug}
        key={featuredBlogPost?.id}
        isFeatured
      />
      <div className="blog-categories">
        <div className="title-container">
          <div className="vertical-strip" />
          <h1>{t("BlogSection.posts")}</h1>
        </div>
        <aside className="blog-categories-filter">
          <p>{t("BlogSection.show")}</p>
          <ul>
            {categories.edges.map(edge => (
              <li
                className={
                  pathname.includes(edge.node.title.toLowerCase())
                    ? "category active"
                    : "category"
                }
                key={edge.node.contentful_id}
              >
                <Link to={`/blog/category/${edge.node.title.toLowerCase()}`}>
                  {edge.node.title}
                </Link>
              </li>
            ))}
          </ul>
        </aside>
      </div>
      {posts.edges.map(({ node }, index) => (
        <BlogItem
          category={node?.category?.title}
          title={node?.title}
          author={node?.author?.name}
          date={node?.publishDate}
          summary={node?.description?.description}
          image={node?.heroImage?.image}
          imageAlt={node?.heroImage?.altText}
          slug={node?.slug}
          key={node?.id}
          isLastItem={posts.edges.length === index + 1 ? true : false}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query($title: String, $locale: String, $featuredCategoryId: String) {
    posts: allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: {
        category: {
          title: { eq: $title }
          contentful_id: { ne: $featuredCategoryId }
        }
        node_locale: { eq: $locale }
      }
    ) {
      totalCount
      edges {
        node {
          id
          title
          publishDate(formatString: "Do MMMM, YYYY")
          slug
          category {
            title
          }
          description {
            description
          }
          author {
            name
          }
          heroImage {
            ... on ContentfulMainImageComponent {
              contentful_id
              __typename
              altText

              image {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 1024
                )
              }
            }
          }
        }
      }
    }
    categories: allContentfulCategory(
      sort: { fields: title, order: DESC }
      filter: {
        node_locale: { eq: $locale }
        contentful_id: { ne: $featuredCategoryId }
      }
    ) {
      edges {
        node {
          contentful_id
          title
        }
      }
    }
    featuredPost: allContentfulFeaturedPost(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          featuredBlogPost {
            ... on ContentfulBlogPost {
              contentful_id
              __typename
              id
              title
              description {
                description
              }
              category {
                title
              }
              publishDate(formatString: "Do MMMM, YYYY")
              slug
              author {
                name
              }
              heroImage {
                ... on ContentfulMainImageComponent {
                  contentful_id
                  __typename
                  altText
                  image {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 1024
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
