import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import BlogItemContent from "../BlogItemContent/BlogItemContent"

import "./BlogItem.scss"

export default function BlogItem({
  category,
  title,
  author,
  date,
  summary,
  image,
  imageAlt,
  slug,
  isFeatured,
  isLastItem,
  isOnLandingPage,
}) {
  return (
    <article
      className={
        isFeatured
          ? "blog-post featured-post"
          : isOnLandingPage
          ? "blog-post fullscreen-image"
          : "blog-post"
      }
    >
      <BlogItemContent
        category={category}
        title={title}
        author={author}
        date={date}
        summary={summary}
        slug={slug}
        isFeatured={isFeatured}
        isOnLandingPage={isOnLandingPage}
      />
      <GatsbyImage
        imgStyle={{ objectFit: "contain" }}
        image={image?.gatsbyImageData}
        alt={imageAlt}
      />
      {!isLastItem && !isFeatured && <div className="blog-post-divider" />}
    </article>
  )
}
