import React from "react"
import { useTranslation } from "react-i18next"

import Link from "../Link/Link"
import LinkButton from "../LinkButton/LinkButton"

import "./BlogItemContent.scss"

export default function BlogItemContent({
  category,
  slug,
  title,
  author,
  date,
  summary,
  isFeatured,
  isOnLandingPage,
}) {
  const { t } = useTranslation()

  return (
    <div
      className={
        isOnLandingPage ? "blog-item-content landing-page" : "blog-item-content"
      }
    >
      <header>
        {isOnLandingPage ? (
          <>
            <h2 id="blog" className="section-title">
              {t("BlogSection.dayraBlog")}
            </h2>
            <h3>{t("BlogSection.latestUpdates")}</h3>
            <LinkButton variant="primary" to="/blog/">
              {t("BlogSection.toBlog")}
            </LinkButton>
          </>
        ) : (
          <p className="category">{category?.toUpperCase()}</p>
        )}

        <Link to={`/blog/${slug}`}>
          <h2>{title}</h2>
        </Link>
        <div className="meta-info">
          <div className="byline">
            <p className="author">{author}</p>
            <span className="dash">-</span>
            <p className="publish-date">{date}</p>
          </div>
        </div>
      </header>
      <p className="blog-summary">{summary}</p>
      {isFeatured ? (
        <LinkButton to={`/blog/${slug}`} variant="transparent">
          {t("BlogSection.readMore")}
        </LinkButton>
      ) : (
        <LinkButton to={`/blog/${slug}`} variant="underline">
          {t("BlogSection.readMore")}
        </LinkButton>
      )}
    </div>
  )
}
